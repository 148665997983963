import { AuthService, getDeviceId, getSessionId } from 'common';
import { clearSession } from 'pages/Authentication/hooks';
import { call, put } from 'redux-saga/effects';
import { setAppInit, updateActivationStatus, updateActivationToken } from '..';
import { GetEnvConfigurationSaga } from './GetEnvConfigurationSaga';
import { userLoginActionSaga } from './LoginActionSaga';

export function* appStartTransactionSaga(): any {
    try {

        const configuration = yield call(GetEnvConfigurationSaga);
        yield put(updateActivationToken(configuration?.activation_token));

        let token = localStorage.getItem('accessToken');

        getSessionId();
        getDeviceId();

        if (token && configuration?.activation_token) {
            sessionStorage.setItem('accessToken', token);
            const response = yield AuthService.GetUser.call({
                headers: {
                    'x-activation-token': configuration.activation_token,
                    'x-device-id': localStorage.getItem('deviceId'),
                    'x-session-id': sessionStorage.getItem('sessionId'),
                },
            });

            if (response?.data) {
                yield call(userLoginActionSaga, {
                    isAppInit: true,
                    token,
                    activation_token: configuration.activation_token,
                    user: response.data,
                });
            } else {
                clearSession();
            }
        }
    } catch (err: any) {
        console.error(err);
        if (err?.data?.message === '$00014') {
            yield put(updateActivationToken(''));
            yield put(updateActivationStatus('expired'));
        }
    } finally {
        yield put(setAppInit(true));
    }
}
