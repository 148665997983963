import { AuthService, getMessages } from 'common';
import { Notification, showMessageModal } from 'components';
import { IUserInfo } from 'pages/Authentication/entities';
import { updateUserAccountInfo } from 'pages/Authentication/store';
import { getDatasetListTransaction } from 'pages/Datasets/store/actions';
import { getModelListTransaction } from 'pages/Models/store/actions';
import { getValidationListTransaction } from 'pages/Validation/views/summary/store/actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/selectors';

export function useActivateProject({ projectId }: { projectId: string }) {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const localStorageData = localStorage.getItem('activeProject')
    const [activeProjectId, setActiveProjectId] = useState<string | null>()
    const isActiveProject = activeProjectId === projectId;
    useEffect(() => {
        const activeProject = user?.projectOptions?.activeId ?? localStorageData === getMessages('001252') ? null : localStorageData;
        setActiveProjectId(activeProject);
    }, [user?.projectOptions?.activeId, localStorageData, user?.projectOptions?.defaultId]);


    function changeProject(value: string) {
        isActiveProject ? deactivateProject() : activateProject();

        async function deactivateProject() {
            const projectOptions = {
                activeId: "",
                defaultId: user?.projectOptions.defaultId || '',
                isStrict: false,
            };
            localStorage.setItem('activeProject', getMessages('001252'));
            const res = await AuthService.UpdateUser.post({ projectOptions });
            dispatch(updateUserAccountInfo(res?.data as IUserInfo));
            dispatch(getModelListTransaction());
            dispatch(getDatasetListTransaction());
            dispatch(getValidationListTransaction());
            Notification.success({ content: getMessages('001245') });
        }

        async function activateProject() {

            const projectOptions = {
                activeId: value,
                defaultId: user?.projectOptions.defaultId || '',
                isStrict: false,
            };
            localStorage.setItem('activeProject', value || '');
            const res = await AuthService.UpdateUser.post({ projectOptions });
            dispatch(updateUserAccountInfo(res?.data as IUserInfo));
            dispatch(getModelListTransaction());
            dispatch(getDatasetListTransaction());
            dispatch(getValidationListTransaction());
            Notification.success({ content: getMessages('001244') });
        }

   
    }

    return function handleActivation() {
        showMessageModal({
            type: 'info',
            content: isActiveProject ? getMessages('001260') : getMessages('001259'),
            cancelButton: {
                enabled: true,
            },
            okButton: {
                text: getMessages('000397'),
                color: 'primary',
            },
        }).then(() => changeProject(projectId));
    };
}
