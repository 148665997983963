import cx from 'classnames';
import { AuthService, getMessages, isNullOrUndefined } from 'common';
import { GButton, Notification, showMessageModal } from 'components';
import { AddLine18 } from 'components/assets/icons';
import { IUserInfo } from 'pages/Authentication/entities';
import { updateUserAccountInfo } from 'pages/Authentication/store';
import { getDatasetListTransaction } from 'pages/Datasets/store/actions';
import { getModelListTransaction } from 'pages/Models/store/actions';
import { setActiveKey } from 'pages/Project';
import { useGetProjectListQuery } from 'pages/Project/store/ProjectService';
import { getValidationListTransaction } from 'pages/Validation/views/summary/store/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/selectors';
import Styles from './Styles.module.scss';

export function useProjectSelection() {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const localStorageData = localStorage.getItem('activeProject');
    const [activeProjectId, setActiveProjectId] = useState<string | null>();

    const { data = [] } = useGetProjectListQuery(undefined, { skip: !user?.userId });
    const menuItemList = data?.filter(item => !item.isArchived).map(item => ({ title: item.name, value: item.projectId }));

    useEffect(() => {
        const activeProject = user?.projectOptions?.activeId
            ? user?.projectOptions?.activeId
            : localStorageData === getMessages('001252')
            ? null
            : localStorageData;
        setActiveProjectId(activeProject);
    }, [user?.projectOptions?.activeId, localStorageData, user?.projectOptions?.defaultId]);

    function onChange(value: string | undefined) {
        showMessageModal({
            type: 'info',
            content: getMessages('001254'),
            cancelButton: {
                enabled: true,
            },
            okButton: {
                text: getMessages('000397'),
                color: 'primary',
            },
        }).then(() => changeProject(value));
    }

    async function changeProject(value: string) {
        const projectOptions = {
            activeId: value,
            defaultId: user?.projectOptions.defaultId || '',
            isStrict: false,
        };
        const res = await AuthService.UpdateUser.post({ projectOptions });
        localStorage.setItem('activeProject', value || '');
        setActiveProjectId(value || '');
        dispatch(updateUserAccountInfo(res?.data as IUserInfo));
        dispatch(getModelListTransaction());
        dispatch(getDatasetListTransaction());
        dispatch(getValidationListTransaction());

        if (isNullOrUndefined(value)) {
            localStorage.setItem('activeProject', getMessages('001252'));
            setActiveProjectId(null);
            Notification.success({ content: getMessages('001245') });
            return;
        }

        Notification.success({ content: getMessages('001253') });
    }

    function renderProjectDropdown(Content: React.ReactElement) {
        return (
            <div className={Styles.ProjectDropdown}>
                {Content}
                <GButton
                    endIcon={<AddLine18 />}
                    size="small"
                    onClick={() => setActiveKey('project-create')}
                    id="navbar-project-selection"
                    className={Styles.CreateProjectButton}
                >
                    <span className="button-bold">{getMessages('000833')}</span>
                </GButton>
            </div>
        );
    }

    const renderProjectOption = (item: ISelectItem) => {
        const isActive = item.value === activeProjectId;
        return (
            <div className={Styles.SelectItem}>
                <div className={cx(Styles.Circle, { [Styles.Active]: isActive, [Styles.InActive]: !isActive })} />
                <div className="pBody-normal truncate">{item.title ?? 'Select Project'}</div>
            </div>
        );
    };

    return { menuItemList, onChange, activeProjectId, renderProjectOption, renderProjectDropdown };
}

interface ISelectItem {
    title: string;
    value: string;
}
