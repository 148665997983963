import { APIService, DeploymentStatus, getMessages } from 'common';
import { Notification } from 'components';
import { put, select } from 'redux-saga/effects';
import { selectModelData } from 'redux/selectors';
import { setArchivedModels, setModelList, updateModelCommonState, updateModelList } from '..';
import { IDeploymentTrackingResponse, IModel, IModelCardResponsePayload, getModelCardData } from '../../entities';

export function* getModelListTransactionSaga(): any {
    try {
        let projectId = localStorage.getItem('activeProject') === getMessages("001252") ? null : localStorage.getItem('activeProject');

        yield put(updateModelCommonState({ modelListLoading: true }));

        const response: { data: Array<IModelCardResponsePayload> } = yield APIService.Models.GetModelList.call({
            params: { projectId },
        });

        if (response?.data) {
            let payload = response?.data.map(getModelCardData);
            let modelList: Array<IModel> = [];
            let archivedModelList: Array<IModel> = [];
            payload.forEach(item => {
                if (item.archived) {
                    archivedModelList.push(item as any);
                } else {
                    modelList.push(item as any);
                }
            });
            yield put(setModelList(modelList));
            yield put(setArchivedModels(archivedModelList));

            return payload;
        }
    } catch (e) {
        console.log(e);
    } finally {
        yield put(updateModelCommonState({ modelListLoading: false }));
    }
}

export function* updateDeployedModelsSaga(update: IDeploymentTrackingResponse | Array<IDeploymentTrackingResponse>): any {
    try {
        if (!update) return;

        if (!Array.isArray(update)) {
            update = [update];
        }

        for (let updateItem of update) {
            let model: IModel = yield select(selectModelData(updateItem.model_id));

            if (!model) return;

            if (updateItem.status === DeploymentStatus.DOWN) {
                updateItem.status = DeploymentStatus.STOP;
            }

            if (model?.deploymentStatus !== DeploymentStatus.UP && updateItem.status === DeploymentStatus.UP) {
                Notification.success({
                    content: getMessages('000424')?.replace('$', model.modelName),
                    options: { showTime: true, toastId: model.modelName },
                });
            }

            const payload: IModel = {
                ...model,
                deploymentStatus: updateItem.status || DeploymentStatus.STOP,
                deploymentId: updateItem.deployment_id || '',
            };

            yield put(updateModelList(payload));
        }
    } catch (error) {
        console.log(error);
    }
}
