import { all, call, select, takeEvery } from '@redux-saga/core/effects';
import { GetMethod, IWindowLevel, PlatformService, ServiceEnum, _entries, _uuid, getMessages } from 'common';
import { Notification, showMessageModal } from 'components';
import { IAugmentationProperties, IImageControls, IUserTransformationParams } from 'pages/Playground/entities';
import { IBaseAction } from 'redux/entities';
import { getStartValidationMessageContent } from '../components/ActionCard/hooks/getStartValidationMessageContent';
import { IValidationRunRequestPayload } from '../entities';
import * as actions from './actions';
import {
    selectAugmentationProperties,
    selectBatchSize,
    selectGenerateMetrics,
    selectImageControls,
    selectImageCount,
    selectNumberOfWorker,
    selectProbabilityMap,
    selectRowCount,
    selectWindowLevel,
} from './selectors';

export default function* rootSaga() {
    yield all([
        takeEvery(actions.START_VALIDATION_RUN_ACTION, startValidationRunSaga),
        takeEvery(actions.STOP_VALIDATION_RUN_ACTION, stopValidationRunSaga),
    ]);
}

function* startValidationRunSaga({ payload }: IBaseAction<IValidationRunRequestPayload>): any {
    try {
        const request_id = _uuid();
        const { model, dataset, activeProjectId } = payload;

        const batchSize: number = yield select(selectBatchSize);
        const imageCount: number = yield select(selectImageCount);
        const rowCount: number = yield select(selectRowCount);
        const numberOfWorker: number = yield select(selectNumberOfWorker);
        const windowLevel: IWindowLevel = yield select(selectWindowLevel);
        const generateMetrics: boolean = yield select(selectGenerateMetrics);
        const augmentationProperties: IAugmentationProperties = yield select(selectAugmentationProperties);
        const imageControls: IImageControls = yield select(selectImageControls);
        const probabilityMap: Dictionary<number> = yield select(selectProbabilityMap);

        const user_transformations: IUserTransformationParams = {
            basic: { userAugmentation: [], imageControls: [] },
            advance: {},
        };
        _entries(imageControls).forEach(([key, value]) => {
            probabilityMap[key] &&
                user_transformations.basic.imageControls.push({ name: key, args: value, probability: probabilityMap[key] });
        });

        _entries(augmentationProperties).forEach(([key, value]) => {
            probabilityMap[key] &&
                user_transformations.basic.userAugmentation.push({
                    name: key,
                    args: value.value,
                    probability: probabilityMap[key],
                });
        });

        let requestPayload: Dictionary = {
            batch_size: batchSize,
            user_transformations,
            model_id: model?.modelId,
            deploy_id: model.deploymentId,
            dataset_id: dataset?.datasetId,
            n_workers: numberOfWorker,
            generate_metrics: generateMetrics,
        };

        let subDescription;

        if (dataset?.tabular) {
            requestPayload.n_rows = rowCount;
            subDescription = `Rows: ${rowCount}`
        } else {
            requestPayload.n_images = imageCount === dataset?.sampleSize ? -1 : imageCount;
            requestPayload.windowing = windowLevel;
            subDescription = `Images: ${imageCount}`
        }

        if (activeProjectId) {
            requestPayload.project_id = activeProjectId;
        }
        const userTransformationLength =
            user_transformations.basic.imageControls.length > 0 || user_transformations.basic.userAugmentation.length > 0;

        let description = `Batch size: ${batchSize}, ${subDescription} , Worker: ${numberOfWorker} , Generate Metric: ${generateMetrics ? 'on' : 'off'
            } , Robust Validation: ${userTransformationLength ? 'applied' : 'not applied'}`;

        if (windowLevel) {
            description = `${description} , Window Level: W:${windowLevel?.ww}, L:${windowLevel?.wc} `;
        }

        const content = getStartValidationMessageContent(model, dataset, description);

        yield call(showMessageModal, {
            type: 'info',
            content,
            id: 'start-validation-message-modal',
            cancelButton: {
                enabled: true,
            },
            okButton: {
                text: getMessages('000092'),
            },
        });

        const { data } = yield PlatformService.Prediction.StartBatch.call(requestPayload, {
            headers: { 'x-request-id': request_id },
        });

        if (data?.status_code === '202') {
            Notification.success({ content: getMessages('000310') });
        }
    } catch (e) {
        console.log(e);
    }
}

function* stopValidationRunSaga(sagaAction: any): any {
    try {
        const request_id = _uuid();

        const StopValidation = new GetMethod(`batch/halt/${sagaAction.payload}`, null, ServiceEnum.Prediction);

        const { data } = yield StopValidation.call({
            headers: { 'x-request-id': request_id },
        });

        if (data?.status === 'HALTED') {
            Notification.success({ content: getMessages('000311') });
        }
    } catch (e) {
        console.log(e);
    }
}
