import { APISlice } from 'app/store/APISlice';
import { _isEmpty, ServiceEnum, socketService } from 'common';
import {
    getModelCardData,
    GetTransactionListRequestPayload,
    IModel,
    IModelCardResponsePayload,
    IModelPathReq,
    IModelPathRes,
    ITransaction,
} from '../entities';
import { IRemotePlatformStatusResponse } from '../entities/IDeploymentTracking';
import { IModelTraining } from '../entities/IModelTrainingParams';
import { ITrainModel } from '../entities/ITrainModel';
import { ITrainingConfig } from '../entities/ITrainingConfig';

export const extendedApiSlice = APISlice.injectEndpoints({
    endpoints: builder => ({
        getModelList: builder.query<Array<IModel>, string | undefined>({
            query: projectId => `${ServiceEnum.Api}/Models/GetModelList?projectId=${projectId || ''}`,
            transformResponse(baseQueryReturnValue: Array<IModelCardResponsePayload>, meta, arg) {
                return baseQueryReturnValue?.map(getModelCardData) || [];
            },
            providesTags: (result, error, projectId) =>
                projectId ? [{ type: 'ModelList', id: projectId }, 'ModelList'] : ['ModelList'],
        }),

        getMonaiMetadata: builder.mutation<Blob | undefined, { bundle_name: string }>({
            async queryFn({ bundle_name }, api, extraOptions, baseQuery) {
                if (_isEmpty(bundle_name)) return { error: new Error('Bundle name is empty') };

                const { data } = await baseQuery({
                    url: `${ServiceEnum.Management}/model/monai/metadata/${bundle_name}`,
                });

                return { data } as any; //type check
            },
        }),
        getModel: builder.query<IModel, string | undefined>({
            query: model_id => ({
                url: `${ServiceEnum.Api}/Models/GetModel`,
                params: { model_id },
            }),
            transformResponse(baseQueryReturnValue: IModelCardResponsePayload, meta, dataset_id) {
                return getModelCardData(baseQueryReturnValue);
            },
        }),
        getPlatformRemoteHealth: builder.query<IRemotePlatformStatusResponse, any>({
            query: () => `${ServiceEnum.Deployment}/platform/remote/health`,
        }),
        getModelTrainingList: builder.query<Array<IModelTraining>, any>({
            query: () => ({
                url: `${ServiceEnum.Api}/Training/GetModelTrainingList`,
            }),
        }),
        getModelTrainingItem: builder.query<IModelTraining, any>({
            query: train_job_id => ({
                url: `${ServiceEnum.Api}/Training/GetModelTrainingItem`,
                params: { train_job_id },
            }),
        }),
        getMetadataDistribution: builder.query<any, any>({
            query: metadata_id => ({
                url: `${ServiceEnum.Api}/Datasets/GetMetadataDistribution`,
                params: { metadata_id },
            }),
        }),
        updateExperimentalMode: builder.mutation<any, string>({
            query: model_id => ({
                url: `${ServiceEnum.Api}/Training/UpdateExperimentalMode`,
                method: 'POST',
                body: {
                    model_id,
                },
            }),
        }),
        getTrainingConfigList: builder.query<ITrainingConfig, any>({
            query: train_model_config_id => ({
                url: `${ServiceEnum.Api}/Training/GetTrainingConfigList`,
                params: { train_model_config_id },
            }),
        }),
        getTrainModelList: builder.query<Array<ITrainModel>, undefined>({
            providesTags: ['TrainModelList'],
            query: () => ({
                url: `${ServiceEnum.Api}/Training/GetTrainModelList`,
            }),
        }),
        getTrainModel: builder.query<ITrainModel, any>({
            query: train_model_id => ({
                url: `${ServiceEnum.Api}/Training/GetTrainModel`,
                params: { train_model_id },
            }),
        }),
        deleteTrainJob: builder.mutation<void, string>({
            query: train_job_id => ({
                url: `${ServiceEnum.Api}/Training/DeleteTrainJob`,
                method: 'POST',
                body: { train_job_id },
                responseHandler: response => response.text(),
            }),
        }),
        updateNameAndAccessOptions: builder.mutation<any, any>({
            query: ({ train_job_id, train_job_access_options, train_job_name }) => ({
                url: `${ServiceEnum.Api}/Training/UpdateNameAndAccessOptions`,
                method: 'POST',
                body: { train_job_id, train_job_access_options, name: train_job_name },
                responseHandler: response => response.text(),
            }),
        }),
        favoriteTrainJobTracking: builder.mutation<any, any>({
            query: ({ train_job_id, favorite }) => ({
                url: `${ServiceEnum.Auth}/FavoriteTrainJobTracking`,
                method: 'POST',
                body: { train_job_id, favorite },
            }),
        }),
        uploadModelPath: builder.mutation<IModelPathRes, IModelPathReq>({
            query: ({ framework, problem_type, path }) => ({
                url: `${ServiceEnum.Management}/model/upload/path`,
                params: { framework, problem_type, path, src: 'local' },
                method: 'POST',
            }),
        }),
        getModelArtifactProps: builder.query<any, string>({
            query: name_list => ({
                url: `${ServiceEnum.Api}/Models/GetModelArtifactProps`,
                params: { name_list },
            }),
        }),

        getPredictionList: builder.query<any, any>({
            queryFn(batchJobList, api, extraOptions, baseQuery) {
                const params = new URLSearchParams();
                batchJobList.forEach((value: string) => params.append('batchJobList', value));

                return baseQuery({
                    url: `${ServiceEnum.Api}/Validation/GetPredictionList`,
                    params,
                });
            },
        }),
        getTransactionList: builder.query<Array<ITransaction>, GetTransactionListRequestPayload>({
            query: ({ batch_job_id } = {}) => ({
                url: `${ServiceEnum.Api}/Validation/GetTransactionList`,
                params: { batch_job_id },
            }),
            async onCacheEntryAdded({ batch_job_id }, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                await cacheDataLoaded;

                socketService.subscribeEvent('transaction', batch_job_id, { batch_job_id }, data => {
                    updateCachedData(draft => {
                        const updateDraft = (item: ITransaction) => {
                            if (!item?.transaction_id || item?.batch_job_id !== batch_job_id) return;
                            const index = draft?.findIndex(d => d.transaction_id === item.transaction_id);
                            if (index !== -1) {
                                Object.assign(draft[index], item);
                            } else {
                                draft?.unshift(item);
                            }
                        };
                        if (Array.isArray(data)) {
                            data?.forEach(item => updateDraft(item.payload));
                        } else {
                            updateDraft(data?.payload);
                        }
                    });
                });

                cacheEntryRemoved.then(() => {
                    socketService.unSubscribeEvent('transaction', batch_job_id);
                });
            },
        }),
        getModelHubList: builder.query<any, string>({
            query: ()=> ({
                url: `${ServiceEnum.Management}/modelhub`,
            }),
        }),
        uploadModelHubInfo: builder.mutation<{model_id:string}, {model_handler:string ,model_url:string}>({
            query: ({ model_handler,model_url }) => ({
                url: `${ServiceEnum.Management}/model/upload/hub`,
                params: { model_handler,model_url },
                method: 'POST',
            }),
        }),
        getTransaction: builder.query<ITransaction, any>({
            query: transaction_id => ({
                url: `${ServiceEnum.Api}/Validation/GetTransaction`,
                params: { transaction_id },
            }),
        }),
    }),
});

export const {
    useGetModelListQuery,
    useGetMonaiMetadataMutation,
    useGetModelQuery,
    useGetModelTrainingListQuery,
    useGetModelTrainingItemQuery,
    useGetMetadataDistributionQuery,
    useGetPlatformRemoteHealthQuery,
    useUpdateExperimentalModeMutation,
    useGetTrainingConfigListQuery,
    useGetTrainModelListQuery,
    useGetTrainModelQuery,
    useDeleteTrainJobMutation,
    useUpdateNameAndAccessOptionsMutation,
    useFavoriteTrainJobTrackingMutation,
    useUploadModelPathMutation,
    useGetModelArtifactPropsQuery,
    useGetPredictionListQuery,
    useGetTransactionListQuery,
    useGetModelHubListQuery,
    useUploadModelHubInfoMutation,
    useGetTransactionQuery,
} = extendedApiSlice;
