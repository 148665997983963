import { getMessages } from 'common';
import { useGetProjectListQuery } from 'pages/Project/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/selectors';

export function useCheckProject() {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const { data, isSuccess } = useGetProjectListQuery(undefined, { skip: !user?.userId });
    const activeProjectId = user?.projectOptions?.activeId  ?? localStorage.getItem('activeProject') === getMessages('001252') ? null : localStorage.getItem('activeProject');
    useEffect(() => {
        if (!isSuccess || !activeProjectId || !user?.email) return;

        if (data?.some(p => p?.projectId === activeProjectId)) return;
    }, [activeProjectId, data, dispatch, isSuccess, user?.email]);
}
